import React, { useEffect, useState } from "react";
import { Col, Input, Row, Table, message } from "antd";
import SubLayout from "../../components/layout/SubLayout";
import { DeleteCandidate } from "../../components/common/Popup";
import moment from "moment";
import API_MANAGER from "../../API";
import CustomPagination from "../../components/common/CustomPagination";
// import { useNavigate } from "react-router-dom";

function SubscriptionHistory() {
  // const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState("all");
  const [filterStatus, setFilterStatus] = useState("all");

  const getData = async () => {
    try {
      let params = {
        limit: 10,
        page: page,
        sort: '-startDate'
      };
      if (filter !== "all") {
        params.role = filter;
      }
      if (filterStatus !== "all") {
        params.active = filterStatus;
      }
      const response = await API_MANAGER.getAllSubscriptions(params);
      console.log(response?.data?.data);
      setData(response?.data?.data);
    } catch (error) {
      message.error(error.response.data.message);
    }
  };
  useEffect(() => {
    if (page) {
      getData();
    }
  }, [page, filter, filterStatus]);

  const columns = [
    {
      title: "No.",
      dataIndex: "si",
      width: "80px",
      render: (_, row, index) => {
        return (
          <span className="cursor-pointer"> {10 * (1 - 1) + index + 1} </span>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, row) => (
        <span className="text-capitalize">
          {row?.userId?.firstName + " " + row?.userId?.lastName}
        </span>
      ),
    },
    {
      title: "UserType",
      dataIndex: "role",
      key: "role",
      render: (text, row) => (
        <span>{row?.role === 1 ? "Client" : "Candidate"}</span>
      ),
      filters: [
        {
          text: "ALL",
          value: "all",
        },
        {
          text: "Client",
          value: 1,
        },
        {
          text: "Candidate",
          value: 0,
        },
      ],
      defaultFilteredValue: ["all"],
      filterMultiple: false,
      onFilter: (value, record) => {
        setFilter(value);
        return record;
      },
    },
    {
      title: "Subscription Type",
      dataIndex: "type",
      key: "type",
      render: (text, row) => <span>{row?.sp ? row?.sp?.name : "-"}</span>,
    },
    {
      title: "Price",
      dataIndex: "default_price",
      key: "default_price",
      render: (text, row) => (
        <span>
          {row?.sp
            ? "$" + row?.sp?.default_price + "/" + row?.sp?.recurringType
            : "-"}
        </span>
      ),
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (text, row) => (
        <span>{moment(row?.startDate).format("DD-MM-YYYY")}</span>
      ),
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (text, row) => (
        <span>
          {moment(row?.startDate).add(1, "year").format("DD-MM-YYYY")}
        </span>
      ),
    },
    {
      title: <span>status</span>,
      dataIndex: "active",
      key: "active",
      align: "center",
      render: (text, row) => <span>{row?.active ? "active" : "Expired"}</span>,
      filters: [
        {
          text: "ALL",
          value: "all",
        },
        {
          text: "Active",
          value: true,
        },
        {
          text: "Expired",
          value: false,
        },
      ],
      defaultFilteredValue: ["all"],
      filterMultiple: false,
      onFilter: (value, record) => {
        setFilterStatus(value);
        return record;
      },
    },
  ];
  return (
    <SubLayout page={"Subscription Detail"}>
      <Row className="search_container" align={"middle"} gutter={5}>
        <Col>
          <Input.Search
            placeholder="Search"
            //   value={search}
            allowClear
            //   onChange={(e) => setSearch(e?.target?.value)}
          />
        </Col>
        <Col className="btn_Col">
          {/* <Button
              className="bulk_button"
              onClick={() => navigate(`/subscription/create`)}
            >
              Add Subscription
            </Button> */}
        </Col>
      </Row>

      <Row className="table_container">
        <Table
          columns={columns}
          dataSource={data?.result}
          pagination={false}
          className="w-100"
          rowKey={"id"}
          // loading={loading}
          scroll={{
            y: "calc(100vh - 360px)",
          }}
        />
        <div className="w-100">
          <CustomPagination
            currentPage={page}
            setCurrentPage={setPage}
            total={data?.totalCount}
            itemPerPage={10}
          />
        </div>
      </Row>
      <DeleteCandidate
        //   confirmDelete={deleteConfirm}
        //   visible={openDeleteModal}
        //   setVisible={setDeleteModal}
        text={"Subscription"}
        //   loading={confirmLoading}
      />
    </SubLayout>
  );
}
export default SubscriptionHistory;
